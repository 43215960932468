// Modules exported here will be included in bundle.js
export function initAdobe(IS_STAGING) {
	const stagingUrl = "https://assets.adobedtm.com/a3acdf76791c/3c29558cb798/launch-b3f81ce0fb98-development.min.js";
	const prodUrl = "https://assets.adobedtm.com/a3acdf76791c/3c29558cb798/launch-4f5345ebde9d.min.js";
	const script = document.createElement('script');
	script.async = true;
	script.src = IS_STAGING ? stagingUrl : prodUrl;
	document.head.appendChild(script);
}

export function buildAnalyticsOnceLoaded(searchParams = null) {
	if (!('wa_view' in window)) {
		//handle race condition
		setTimeout(buildAnalyticsOnceLoaded, 1000);
	} else {
		//set up page view
		const uri = new URL(window.location.href);
		window.nbs_ddl = window.nbs_ddl || {};
		if (searchParams) {
			let resultsCount = searchParams.resultsCountByVertical ? Object.values(searchParams.resultsCountByVertical).reduce((total, vertical) => {
				return total + vertical.totalResultsCount
			}, 0) : searchParams.resultsCount

			nbs_ddl.page.search = {
				'term': searchParams.queryString,
				'result_count': resultsCount,
				'event': 'standard search'
			}
		}
		// If internal link click add `navigation` info to pageview analytics object
		if (document.referrer && new URL(document.referrer).hostname == window.location.hostname) {
			const linkData = sessionStorage.getItem('linkText');
			if (linkData) {
				nbs_ddl.page['navigation'] = {
					'element': {
						'type': 'primary link',
						'identifier': linkData
					}
				}
				sessionStorage.removeItem('linkText');
			}
		}
		const isInitialLoad = sessionStorage.getItem('isInitialLoad');
		if(isInitialLoad !== 'true') {
		  wa_view();
		} else {
		  sessionStorage.removeItem('isInitialLoad');
		}

		// Add listeners to different links/buttons to trigger analytics event
		let allLinkButtonToBeProcessed;
		if (searchParams) { //don't tag results on load because they'll be tagged in the onUniversalSearch or onVerticalSearch call
			allLinkButtonToBeProcessed = document.querySelectorAll(".Answers-directAnswerResult button, .Answers-directAnswerResult a, .yxt-Results a, .yxt-Results button, .js-answersUniversalResults a, .js-answersUniversalResults button");
		} else { //tag all of the static links and buttons in the on page load call
			allLinkButtonToBeProcessed = document.querySelectorAll('.Answers-nav a, .Answers-nav button, .Answers-navWrapper a, .Answers-navWrapper button, .yxt-SearchBar a, .yxt-SearchBar button, .Answers-Answersbanner a, .Answers-Answersbanner button, .Answers-Quicklinks a, .Answers-Quicklinks button, .Answers-footer a, .Answers-footer button');
		}
		nbs_ddl.action = nbs_ddl.action || [];
		allLinkButtonToBeProcessed.forEach(link => {
			link.addEventListener('click', () => {
				const href = link.href;
				if (href && href.startsWith('tel:')) {
					nbs_ddl.action.unshift(
						{
							'type': 'click to call',
							'component': {
								'type': 'link',
								'identifier': link.href.replace('/[^0-9]/g', '')
							}
						}
					);
				} else if (link.classList.contains('js-HitchhikerFaqAccordion-toggle')) {
					nbs_ddl.action.unshift(
						{
							'type': 'reveal',
							'component': {
								'type': 'accordion',
								'identifier': link.textContent?.replace(/\s+/g, ' ').trim()
							}
						}
					);
				} else if (href && new URL(href).host == uri.host) { // Link with subsequent page view
					// Link with subsequent page view - data added on pageload not click
					// Add sessionstorage variable to be added on subsequent pageview
					sessionStorage.setItem('linkText', link.textContent);
					return;
				} else { // Link/Button without subsequent page view
					nbs_ddl.action.unshift(
						{
							'type': 'navigation',
							'element': {
								'type': 'primary link',
								'identifier': link.textContent
							}
						}
					);
				}
				wa_action();
			});
		}
		)
	}
}
